// external-link.js

import $ from 'jquery';
import { Modal } from 'bootstrap';

const isLive = window.location.hostname.indexOf('pneumo.be') !== -1;
const defaultWhitelist = ['privacycenter.pfizer.com']
const whitelist = isLive ? [...defaultWhitelist, 'www.pneumo.be'] : [...defaultWhitelist, 'localhost'];

function updateExternalLinks() {
	$.expr.pseudos.external_url = function(a) {
		const parser = document.createElement('a');
		parser.href = a.href;
		const linkHref = parser.hostname;
		const domainHref = window.location.hostname;
		const is_whitelisted = whitelist.indexOf(linkHref);

		if (is_whitelisted >= 0) return false;
		return !a.href.match(/^mailto\:/) && !a.href.match(/^tel\:/) && linkHref !== domainHref;
	};

	$('a:external_url').addClass('extlink');
	$('a.extlink').on('click', function(e) {
    e.preventDefault();
    const modal = new Modal(document.getElementById('modal'), {});
    modal.show();

		const url = $(this).attr('href');
		const $button = $('#modal').find('.btn-primary');

		$button.on('click', function() {
			window.open(url, '_blank', 'noopener');
			$button.off('click');
      modal.hide();
		});
	});
};

window.addEventListener('DOMContentLoaded', updateExternalLinks);
