// scroll-to.js

import * as bootstrap from 'bootstrap';
import $ from 'jquery';

const intersectionHandler = (entry) => {
  const next = entry.target;
  if (next) {
    const current = next.id;
    const anchors = document.querySelectorAll('a[href*="#"]');
    anchors.forEach((anchor) => {
      anchor.classList.remove('is-active');
      if (anchor.href.indexOf(current) > -1) {
        anchor.classList.add('is-active');
      }
    });
    if (window.location.hash !== current) {
      history.replaceState({ page: current }, null, '#' + current);
    }
  }
}

const scrollTo = hash => {
  if (hash) {
    const target = document.querySelector(hash)
    if (target) {
      const yOffset = document.querySelector('#header').getBoundingClientRect().height;
      const y = target.getBoundingClientRect().top + window.pageYOffset - yOffset;
      $('html, body').animate({ scrollTop: y}, 0);
    }
  }
}

const forEachAnchor = (anchor) => {
  anchor.addEventListener('click', (e) => {
    e.preventDefault();
    const menuToggle = document.getElementById('nav-main');
    if (menuToggle.classList.contains('show')) {
      const bsCollapse = new bootstrap.Collapse(menuToggle);
      bsCollapse.hide();
    }
    scrollTo(anchor.hash);
  });
}

const onLoad = () => {
  const { hash } = window.location;
  const anchorSelectors = `
    .front .nav-main-header a[href*="#"],
    .front .nav-main-footer a[href*="#"],
    .front .references a[href*="#"],
    .front .btn-quiz
  `;
  const anchors = document.querySelectorAll(anchorSelectors);
  const sections = document.querySelectorAll('.section');
  const config = { root: null, rootMargin: '0px', threshold: 0.5 };
  let observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
        intersectionHandler(entry);
      }
    })
  }, config);

  sections.forEach(section => observer.observe(section));
  anchors.forEach(forEachAnchor);
  scrollTo(hash);
}

window.addEventListener('load', onLoad);
